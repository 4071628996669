import React, { useEffect, useState, useMemo, useRef } from "react"
import Footer from "../../components/footer"
import { graphql } from "gatsby"
import Header from "../../components/header"
import "../../styles/gia.less"
import SEO from "../../components/SEO"
import { ProductNav } from "../../components/header/Header"
import { Select } from "antd"
const { Option } = Select
import { ProductBlog, AnimationWrap } from "../../components/block"
import Drop from "../../components/drop"
import { Button } from "../../components/ui"
import { ConsultationPopButton } from "../../components/form-biz"
import {
  MapContainer,
  Feedback,
  Solution,
} from "../../pages/products/bare-metal-cloud"
import { lang } from "../../utils"
import { last } from "ramda"
import dayjs from "dayjs"
import {
  Highlight,
  Feature,
  Usecase,
  Success,
} from "../lp/zenlayer-global-accelerator"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts"

import { Tooltip as AntdTooltip } from "antd"

const loadText = require("src/utils").loadText
const static_data = loadText("gia")
// var utc = require("dayjs/plugin/utc")
var timezone = require("dayjs/plugin/timezone")
var advancedFormat = require("dayjs/plugin/advancedFormat")

dayjs.extend(timezone)
dayjs.extend(advancedFormat)

const B1 = () => (
  <div className="gia-b1">
    <div className="gia-b1-content page-middle mobile-padding">
      <AnimationWrap>
        <div className="font-36 semi-bold t-color">{static_data.b1.title}</div>
        <div
          className="font-20 mt-24 block-middle description"
          style={{ maxWidth: "1000px" }}
        >
          {static_data.b1.description}
        </div>
      </AnimationWrap>
    </div>
  </div>
)
const B2 = () => (
  <div className="gia-b2">
    <AnimationWrap>
      <div className="gia-b2-content full-bg">
        <div className="left">
          <img
            src="/products/gia/b2-pic.png"
            alt={static_data.b2.title}
            className="main-pic"
          />
        </div>
        <div className="right">
          <div className="font-36 semi-bold">{static_data.b2.title}</div>
          <div className="font-20 mt-24 description">
            {static_data.b2.description}
          </div>
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const B4 = () => (
  <div className="gia-b4">
    <AnimationWrap>
      <div className="gia-b4-content full-bg">
        <div className="left">
          <img
            src="/products/gia/b4-pic.png"
            alt={static_data.b4.title}
            className="main-pic"
          />
        </div>
        <div className="right">
          <div className="font-36 semi-bold t-color">
            {static_data.b4.title}
          </div>
          <div className="font-20 mt-24">{static_data.b4.description}</div>
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const B3 = () => (
  <div className="gia-b3">
    <div className="page-middle" style={{ position: "relative" }}>
      <div className="pic-container">
        <img src="/products/gia/b3-woman.png" alt={static_data.b3.title} />
      </div>
      <div style={{ overflow: "hidden" }}>
        <AnimationWrap>
          <div className="gia-b3-content">
            <div className="gia-b3-bg ">
              <div className="right">
                <div className="font-36 semi-bold">{static_data.b3.title}</div>
                <div className="font-20 mt-24 description">
                  {static_data.b3.description}
                </div>
              </div>
            </div>
          </div>
        </AnimationWrap>
      </div>
    </div>
  </div>
)
const B5 = () => (
  <div className="gia-b5">
    <AnimationWrap>
      <div className="gia-b5-bg full-bg">
        <div className="left">
          <picture>
            <source
              srcSet="/products/gia/b5-pic-m.png"
              media="(max-width: 640px)"
            />
            <img
              src="/products/gia/b5-pic.png"
              alt={static_data.b5.title}
              className="main-pic"
            />
          </picture>
        </div>
        <div className="right">
          <div className="font-36 semi-bold title">{static_data.b5.title}</div>
          <div className="font-20 mt-24 description">
            {static_data.b5.description}
          </div>
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const Improvement = () => (
  <div className="gia-imp">
    <Drop />

    <div className="gia-imp-bg full-bg">
      <AnimationWrap>
        <div className="font-36 semi-bold t-color">
          {static_data.improvement.title}
        </div>
        <div className="gia-imp-itemContainer page-middle">
          {static_data.improvement.contents.map(
            ({ name, usecase, customer, result }, i) => (
              <div className="gia-imp-item">
                <div className="left">
                  <img src={`/products/gia/imp-${i + 1}.jpg`} alt={name} />
                </div>
                <div className="right">
                  <div className="top">
                    <div className="top-bg">
                      <div className="name">{name}</div>
                      <div className="label">
                        {static_data.improvement.subject.usecase}
                      </div>
                      <div>{usecase}</div>
                      <div className="label">
                        {static_data.improvement.subject.customer}
                      </div>
                      <div>{customer}</div>
                    </div>
                  </div>
                  <div className="bottom">
                    <div>
                      <div className="label">
                        {static_data.improvement.subject.result}
                      </div>
                      <div>{result}</div>
                      <div className="imgContainer">
                        <img
                          src={`/products/gia/result-${i + 1}-${lang}.svg`}
                          alt={result}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        <div className="mt-56">
          <ConsultationPopButton source="zga" buttonWidth={476}>
            {static_data.improvement.buttonText}
          </ConsultationPopButton>
        </div>
      </AnimationWrap>
    </div>
  </div>
)
const Architecture = () => (
  <div style={{ backgroundColor: "rgb(211,233,255)" }}>
    <div className="gia-architecture">
      <AnimationWrap>
        <div className="gia-architecture-bg full-bg">
          <div className="font-36 semi-bold t-color">
            {static_data.architecture.title}
          </div>
          <div className="font-20 description">
            {static_data.architecture.description}
          </div>
          <div className="imgContainer">
            <picture>
              <source
                srcSet={`/products/gia/architecture-pic-${lang}-m.svg`}
                media="(max-width: 640px)"
              />
              <img
                src={`/products/gia/architecture-pic-${lang}.svg`}
                alt={static_data.architecture.title}
              />
            </picture>
          </div>
        </div>
      </AnimationWrap>
    </div>
  </div>
)

const Bar = ({
  data,
}: {
  data: { value: number; label: string; color: string }[]
}) => {
  const max = Math.max(...data.map(v => v.value))
  return (
    <div className="barChart">
      {data.map(({ value, label, color }) => (
        <div>
          <div className="barChart-label">{label}</div>
          <div
            className="barChart-bar"
            style={{
              background: color,
              marginRight: value === 0 ? 0 : "4px",
              width:
                max === 0
                  ? 0
                  : value === max
                  ? "220px"
                  : `${(value / max) * 220}px`,
            }}
          />
          <div className="barChart-value">{`${value} s`}</div>
        </div>
      ))}
    </div>
  )
}
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="lineChart-tooltip">
        <div className="label">{`${label}`}</div>
        {payload.map(({ color, name, value }) => (
          <div className="lineChart-tooltip-row">
            <div className="lineChart-tooltip-label">
              <div className="legend" style={{ background: color }} />
              <div className="name">{name}</div>
            </div>
            <div className="value">
              {typeof value === "number" ? `${value} s` : value}
            </div>
          </div>
        ))}
      </div>
    )
  }

  return null
}
const secondTrans = (str: string) => {
  return str ? parseInt((parseFloat(str) / 1000).toFixed(0)) : "NULL"
}
const getMinOfData = (arr: any) => {
  let optimizedMax = arr[0].optimized || 0
  let unoptimizedMax = arr[0].unoptimize || 0
  let lastMinOptimizedIdx = 0
  let optimizedMin = arr[0].optimized || 0
  arr.slice(1).forEach(({ optimized, unoptimized }, idx) => {
    // if (typeof optimized === "number" && optimized > optimizedMax) {
    //   optimizedMax = optimized
    // }
    if (typeof optimized === "number" && optimized <= optimizedMin) {
      optimizedMin = optimized
      lastMinOptimizedIdx = idx
    }
    if (typeof unoptimized === "number" && unoptimized > unoptimizedMax) {
      unoptimizedMax = unoptimized
      optimizedMax = optimized
      // unoptimizedMaxIdx = idx
    }
  })
  return {
    optimizedMax,
    unoptimizedMax,
    lastMinOptimizedIdx,
  }
}
const ButtonRow = ({ width = 548, link }: any) => (
  <div className="btn-row-wrapper">
    <div className="btn-row">
      <Button
        size="l"
        width={width}
        className="white"
        onClick={() => {
          window.open(
            link || "https://console.zenlayer.com/gia?source=website-gia"
          )
        }}
      >
        {static_data.topBanner.buttonText}
      </Button>
    </div>
    <div className="btn-tip">{static_data.topBanner.buttonTip}</div>
  </div>
)
const NewTop = () => {
  const data = static_data.newTop
  return (
    <div className="newTop">
      <div className="newTop-bg">
        <div className="newTop-topBg">
          <Header theme="dark" />
          <div
            className="title"
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
          <div className="newTop-content">
            <div className="newTop-content-left">
              <div className="description">
                {data.description.map((c, i) => (
                  <div dangerouslySetInnerHTML={{ __html: c }} />
                ))}
              </div>
              <div className="mt-32">
                <ButtonRow />
              </div>
            </div>
            <div>
              <video autoPlay={true} muted controls>
                <source src={`/what-is-zga.mp4`} type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="newTop-bottom">
            <SpeedTest />
            <div className="newTop-bottom-content">
              <div
                className="newTop-subtitle"
                dangerouslySetInnerHTML={{ __html: data.subtitle }}
              />
              <div className="newTop-testTitle">
                <div className="newTop-testTitle-emoji">👈</div>
                <div>{data.testTitle}</div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className="newTop-subtitle"
          dangerouslySetInnerHTML={{ __html: data.subtitle }}
        /> */}
      </div>
    </div>
  )
}
const SpeedTestSelect = ({ value, label, options, onChange, getRef }: any) => {
  const [open, setOpen] = useState(false)
  return (
    <div className="speedTestSelect">
      {value && (
        <label className={`speedTestSelect-label`} htmlFor={label}>
          {label}
        </label>
      )}
      <Select
        open={open}
        onFocus={() => {
          setOpen(true)
        }}
        onBlur={() => {
          setOpen(false)
        }}
        value={value}
        ref={r => {
          if (getRef) {
            getRef(r)
          }
        }}
        onChange={val => {
          onChange(val)
          setOpen(false)
        }}
        style={{ width: "100%", minWidth: "200px" }}
        placeholder={label}
      >
        {options.map(option => (
          <Option value={option}>{option}</Option>
        ))}
      </Select>
    </div>
  )
}
const SpeedTestProgress = ({ title, percentage, value, color, pic }) => (
  <div className="speedTestProgress">
    <div className="speedTestProgress-title" style={{ color }}>
      {title}
    </div>
    <div className="speedTestProgress-res">
      <div className="speedTestProgress-label">
        {static_data.speedTest.progress[percentage]}
      </div>
      <div
        className="speedTestProgress-pic-shadow"
        style={{ background: color, opacity: 1 - percentage / 100 }}
      ></div>
      <div className="speedTestProgress-pic">
        <img src={pic}></img>
      </div>
      <div
        className="speedTestProgress-bar"
        style={{ background: color, width: `${percentage}%` }}
      ></div>
      {value && (
        <div
          className="speedTestProgress-tooltip"
          style={{ background: color }}
        >
          {value}
          <div
            className="speedTestProgress-tooltip-triangle"
            style={{
              borderColor: `${color} transparent transparent transparent`,
            }}
          />
        </div>
      )}
    </div>
  </div>
)
const GoodOne = () => (
  <div className="speedTest-predict-boxItemPop">
    <img src="/products/gia/good-pop.svg" alt="" />
    <span>👍</span>
  </div>
)
export const SpeedTest = ({ isWidget = false }: { isWidget?: boolean }) => {
  const data = static_data.speedTest
  const [url, setUrl] = useState("")
  const [end, setEnd] = useState("Los Angeles")
  const [endList, setEndList] = useState([])
  const [serverList, setServerList] = useState([])
  const [server, setServer] = useState("Hong Kong")
  const [status, setStatus] = useState<"init" | "testing" | "finished">("init")
  const [progressOrigin, setProgressOrigin] = useState(0)
  const [progressZga, setProgressZga] = useState(0)
  const [originRes, setOriginRes] = useState<string>()
  const [zgaRes, setZgaRes] = useState<string>()
  const [predict, setPredict] = useState<{
    time: string
    zga: string
    origin: string
    goodOne: string
  }>()

  const [snapShot, setSnapShot] = useState("")
  const [error, setError] = useState<string>()
  const [iframeHeight, setIframeHeight] = useState(537)
  const zgaReportId = useRef("")
  const [consoleIframe, setConsoleIframe] = useState<string>()
  const fakeTickerTime = useRef(0)
  const fakeTicker = useRef<any>()
  const fetchEnd = async () => {
    const res = await fetch("/api/gia/public/speedtest/server")
    const { success, data } = await res.json()
    if (success) {
      setEndList(data)
    }
  }
  // const [testRemainTime, setTestRemainTime] = useState(0)
  const btnEnable = useMemo(() => {
    return end && server && url.startsWith("http") && status !== "testing"
  }, [end, server, url, status])
  const btnTooltip = useMemo(() => {
    let res = []
    if (!url.startsWith("http")) {
      res.push(data.btnTip.url)
    }
    if (!end || !server) {
      res.push(data.btnTip.end)
    }
    return res
  }, [url, end, server])
  const timeFormat = (ms: number, fixed = 0) =>
    `${parseFloat((ms / 1000).toFixed(fixed))}s`
  const fetchServer = async server => {
    const res = await fetch(`/api/gia/public/speedtest/router?server=${server}`)
    const { success, data } = await res.json()
    if (success) {
      setServerList(data)
    }
  }
  const getGoodOne = data => {
    const zgaIsSuccess = data.zgaResult === "success"
    const originIsSuccess = data.originResult === "success"
    if (zgaIsSuccess && originIsSuccess) {
      return data.zgaForecastTime <= data.originForecastTime ? "zga" : "origin"
    }
    if (zgaIsSuccess || originIsSuccess) {
      return zgaIsSuccess ? "zga" : "origin"
    }
    return ""
  }
  const onFetchResult = async (taskId: string) => {
    const res = await fetch(`/api/gia/public/speedtest?taskId=${taskId}`)
    let reTrigger = false
    const { success, data, code, msg } = await res.json()
    if (success) {
      setProgressZga(data.zgaProgress)
      setProgressOrigin(data.originProgress)
      if (data.screenshotBase64) {
        setSnapShot(data.screenshotBase64)
      }
      if (data.originProgress === 100) {
        if (data.originResult === "success") {
          setOriginRes(timeFormat(data.originTime))
        } else {
          setOriginRes("Fail")
        }
      }
      if (data.zgaProgress === 100) {
        if (data.zgaResult === "success") {
          setZgaRes(timeFormat(data.zgaTime))
        } else {
          setZgaRes("Fail")
        }
      }
      if (data.originProgress === 100 && data.zgaProgress === 100) {
        if (isWidget) {
          if (window.parent) {
            window.parent.postMessage(
              {
                type: "SPEEDTEST_SUCCESS",
                source: "ZEN_WEBSITE",
                payload: {
                  taskId,
                },
              },
              "*"
            )
          }
          setStatus("init")
          return
        }
        setStatus("finished")

        setPredict({
          time: dayjs.unix(data.originPeakTrafficTime).format("h A z"),
          goodOne: getGoodOne(data),

          zga:
            data.zgaResult === "success"
              ? timeFormat(data.zgaForecastTime, 2)
              : "Fail",
          origin:
            data.originResult === "success"
              ? timeFormat(data.originForecastTime, 2)
              : "Fail",
        })
      } else {
        reTrigger = true
      }
    } else {
      if (code === "SPEED_TEST_FAILED") {
        setError(msg)
        setStatus("init")
        return
      }
      reTrigger = true
    }
    if (reTrigger) {
      setTimeout(() => {
        onFetchResult(taskId)
      }, 2000)
    }
  }
  const fakeTrigger = () => {
    if (fakeTickerTime.current === 0) {
      setProgressZga(20)
      setProgressOrigin(20)
    }
    if (fakeTickerTime.current === 500) {
      setProgressZga(40)
      setProgressOrigin(40)
    }
    if (fakeTickerTime.current === 1000) {
      setProgressZga(60)
      setProgressOrigin(60)
    }
    if (fakeTickerTime.current === 1500) {
      setProgressZga(100)
      setZgaRes("1S")
    }
    if (fakeTickerTime.current === 4000) {
      setProgressOrigin(100)
      setOriginRes("18S")
    }
    if (fakeTickerTime.current === 9000) {
      setProgressZga(20)
      setProgressOrigin(20)
      setOriginRes("")
      setZgaRes("")
      fakeTickerTime.current = 0
    }
  }
  // const countingTestRemainTime = testRemainTime => {
  //   if (testRemainTime > 0) {
  //     setTimeout(() => {
  //       setTestRemainTime(testRemainTime - 1)
  //       countingTestRemainTime(testRemainTime - 1)
  //     }, 1000)
  //   }
  // }
  useEffect(() => {
    fakeTickerTime.current = 0

    if (status === "init") {
      setSnapShot("/products/gia/page-preview.jpg")
      fakeTrigger()
      fakeTicker.current = setInterval(() => {
        fakeTrigger()
        fakeTickerTime.current += 500
      }, 500)
    }
    if (status === "finished") {
      // setTestRemainTime(60)
      // countingTestRemainTime(60)
    }
    if (isWidget && window.parent) {
      window.parent.postMessage(
        {
          type: "SPEEDTEST_STATUS_CHANGE",
          source: "ZEN_WEBSITE",
          payload: {
            status,
          },
        },
        "*"
      )
    }
  }, [status])
  const onSendReport = () => {
    setConsoleIframe(
      `https://console.zenlayer.com/website/login?zgaReportId=${
        zgaReportId.current
      }&referral=${encodeURIComponent(
        `${location.origin}/${location.pathname}`
      )}`
    )
  }
  const onTest = async () => {
    if (!btnEnable) {
      return
    }
    if (fakeTicker.current) {
      clearTimeout(fakeTicker.current)
    }
    setSnapShot("")
    setPredict(undefined)
    setOriginRes("")
    setZgaRes("")
    setProgressOrigin(-1)
    setProgressZga(-1)
    setError("")

    setStatus("testing")
    const serverObj = serverList.find(s => s.dropOffCity === server) || {}
    const res = await fetch("/api/gia/public/speedtest", {
      method: "POST",
      body: JSON.stringify({
        testUrl: url,
        server: end,
        router: serverObj.routerName,
        dropOffCity: serverObj.dropOffCity,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
    const { success, data, msg } = await res.json()
    if (success) {
      onFetchResult(data.taskId)
      zgaReportId.current = data.taskId
    } else {
      setError(msg)
      setStatus("init")
    }
  }
  let rightSelect = useRef(null)
  useEffect(() => {
    const onMessage = event => {
      if (event.data.source === "zenconsole") {
        if (event.data.type === "back") {
          setConsoleIframe(undefined)
        }
        if (event.data.type === "newTest") {
          setConsoleIframe(undefined)
          setPredict(undefined)
          setUrl("")
          setEnd(undefined)
          setServer(undefined)
          setProgressOrigin(0)
          setProgressZga(0)
          setOriginRes(undefined)
          setZgaRes(undefined)
          setStatus("init")
        }
        if (event.data.type === "height") {
          setIframeHeight(event.data.payload.height)
        }
      }
    }
    window.addEventListener("message", onMessage, false)
    fetchEnd()
    fetchServer(end)
    return () => {
      window.removeEventListener("message", onMessage)
    }
  }, [])

  return (
    <div
      className={`speedTest ${!consoleIframe ? status : ""} ${
        isWidget ? "widget" : ""
      }`}
    >
      {consoleIframe && (
        <img
          src="/products/gia/zga-promote.svg"
          alt="promote"
          className={`zga-promote ${iframeHeight > 600 ? "longContent" : ""}`}
        />
      )}
      {consoleIframe ? (
        <div
          className={`console-iframe-wrapper ${
            iframeHeight > 600 ? "longContent" : ""
          }`}
          style={{
            height: `${iframeHeight}px`,
            overflowX: "hidden",
            // top: `${iframeHeight > 600 ? -68 : 0}px`,
          }}
        >
          <iframe
            src={consoleIframe}
            style={{ border: "none" }}
            width={578}
            height={iframeHeight}
            scrolling="no"
          />
        </div>
      ) : (
        <div>
          <div className="speedTest-topNav">
            <div className="speedTest-topNav-dot">
              <div />
              <div />
              <div />
            </div>
            <div className="speedTest-topNav-name">{data.title}</div>
          </div>
          <div className="speedTest-content">
            <div className="speedTest-content-input">
              <input
                className="speedTest-content-input-body"
                placeholder={data.placeholder}
                onFocus={() => {
                  if (url === "") {
                    setUrl("https://")
                  }
                }}
                value={url}
                onChange={e => setUrl(e.target.value.trim())}
              />
              <div className="speedTest-content-input-btn-placeholder"></div>
              <AntdTooltip
                overlayClassName="speedTest-reportBtn-tooltip"
                title={
                  btnTooltip.length > 0 ? (
                    <ul>
                      {btnTooltip.map(t => (
                        <li>{t}</li>
                      ))}
                    </ul>
                  ) : undefined
                }
              >
                <div
                  className={`speedTest-content-input-btn ${
                    btnEnable ? "" : "disabled"
                  }${status === "testing" ? " testing" : ""}`}
                  onClick={onTest}
                >
                  {status === "testing" ? (
                    <div className="speedTest-content-input-btn-testingContent">
                      <div className="speedTestProgress-loader"></div>
                      {data.testing}
                    </div>
                  ) : (
                    <div>{`${data.start}`}</div>
                  )}
                </div>
              </AntdTooltip>
            </div>
            <div className="speedTest-content-selectRow">
              <SpeedTestSelect
                value={end}
                onChange={async value => {
                  setServer(undefined)
                  setEnd(value)
                  setServerList([])
                  await fetchServer(value)
                  rightSelect.current.focus()
                }}
                options={endList}
                label={data.clientLocation}
              />
              <div className="speedTest-content-selectRow-icon">
                <img src="/right-arrow.svg" alt="right-arrow" />
              </div>
              <SpeedTestSelect
                getRef={r => (rightSelect.current = r)}
                value={server}
                onChange={setServer}
                options={serverList.map(d => d.dropOffCity)}
                label={data.serverLocation}
              />
            </div>
          </div>
          <div className="speedTest-result">
            <div className="speedTest-result-title">{data.resultTitle}</div>
            <div className="speedTest-result-row">
              <SpeedTestProgress
                title={data.yourResult}
                color="#5B87FF"
                pic={snapShot}
                value={originRes}
                percentage={progressOrigin}
              />
              <div className="speedTest-result-vs">{data.vs}</div>
              <SpeedTestProgress
                title={data.zgaResult}
                pic={snapShot}
                color="#26D98E"
                value={zgaRes}
                percentage={progressZga}
              />
            </div>
            {predict && (predict.origin !== "Fail" || predict.zga !== "Fail") && (
              <div className="speedTest-predict">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.predict.replace(
                      "{}",
                      `<strong>${predict.time}</strong>`
                    ),
                  }}
                  className="speedTest-predict-text"
                />
                <div className="speedTest-predict-box">
                  <div className="speedTest-predict-boxItem">
                    {predict.origin}
                    {predict.goodOne === "origin" && <GoodOne />}
                  </div>
                  <div className="speedTest-predict-boxItem">
                    {predict.zga}

                    {predict.goodOne === "zga" && <GoodOne />}
                  </div>
                </div>
              </div>
            )}

            {status === "testing" && (
              <div className="speedTest-result-loading">
                <div className="speedTestProgress-loader"></div>
                <span>{data.loadingText}</span>
              </div>
            )}

            {error && <div className="speedTest-result-error">{error}</div>}
          </div>
          {status === "finished" && (
            <AntdTooltip
              overlayClassName="speedTest-reportBtn-tooltip"
              title={
                <div>
                  <span>{data.metricsTooltip.title}</span>
                  <ul>
                    {data.metricsTooltip.content.map(c => (
                      <li>{c}</li>
                    ))}
                  </ul>
                </div>
              }
            >
              <div className="speedTest-reportBtn" onClick={onSendReport}>
                {data.reportBtn}
              </div>
            </AntdTooltip>
          )}
        </div>
      )}
    </div>
  )
}
const TopBanner = ({ consoleLink }: any) => {
  const data = static_data.topBanner
  const [lineData, setLineData] = useState([])
  const [time, setTime] = useState("day")
  const [info, setInfo] = useState<any>({})
  const minData = useMemo(() => {
    return lineData.length > 0 ? getMinOfData(lineData) : null
  }, [lineData])

  let restData = useRef([])
  let fetchTicker = useRef<any>(null)

  let fakeDataTicker = useRef<any>(null)
  let fakeDataIndex = useRef<number>(0)
  const range = useMemo(() => {
    return lineData.length > 0
      ? `${lineData[0].date} to ${last(lineData).date}`
      : ""
  }, [lineData])
  const fetchData = async (time: string) => {
    const startUnix = dayjs()
      .subtract(1, time === "day" ? "d" : "week")
      .unix()
    const response = await fetch(
      `/query/probe/harvard?start=${startUnix}&end=${dayjs().unix()}`
    )
    const endUnix = startUnix + 144 * 600
    const data = await response.json()
    if (data.code === 200) {
      if (time === "day") {
        const lineData = data.data.harvard.slice(0, 145).map((d, idx) => ({
          date: dayjs.unix(startUnix + 600 * idx).format("MMM D HH:mm:ss"),
          optimized: secondTrans(d.HongKongPageLoad),
          unoptimized: secondTrans(d.ShangHaiPageLoad),
        }))
        restData.current = data.data.harvard.slice(145)
        if (fakeDataTicker.current) {
          fakeDataIndex.current = 0
          clearInterval(fakeDataTicker.current)
        }
        fakeDataTicker.current = setInterval(() => {
          let newData = restData.current[fakeDataIndex.current] || {
            HongKongPageLoad: 0,
            ShangHaiPageLoad: 0,
          }
          setLineData(lineData => {
            return lineData.concat({
              optimized: secondTrans(newData.HongKongPageLoad),
              unoptimized: secondTrans(newData.ShangHaiPageLoad),
              date: dayjs
                .unix(endUnix + fakeDataIndex.current * 3)
                .format("MMM D HH:mm:ss"),
            })
          })
          fakeDataIndex.current++
        }, 3000)
        setLineData(lineData)
      } else {
        const lineData = data.data.harvard.map((d, idx) => ({
          date: dayjs.unix(startUnix + 600 * idx).format("MMM D HH:mm:ss"),
          optimized: secondTrans(d.HongKongPageLoad),
          unoptimized: secondTrans(d.ShangHaiPageLoad),
        }))
        setLineData(lineData)
      }
      setInfo({
        target: data.data.TargetUrl,
        source: data.data.sourceLocation,
      })
    }
  }

  useEffect(() => {
    fetchData("day")
    fetchTicker.current = setInterval(() => {
      fetchData("day")
    }, 120000)
    return () => {
      if (fetchTicker.current) {
        clearInterval(fetchTicker.current)
      }
    }
  }, [])
  const barData = useMemo(
    () => [
      {
        value: minData ? minData.optimizedMax : 0,
        label: data.title,
        color: "#26D98E",
      },
      {
        value: minData ? minData.unoptimizedMax : 0,
        label: data.internet,
        color: "#5B87FF",
      },
    ],
    [minData]
  )
  const CustomizedLabel: any = (props: any) => {
    const { x, y, index } = props
    return (
      index === minData.lastMinOptimizedIdx && (
        <g>
          <rect
            x={x - 25}
            y={y - 27 - 3}
            width="50"
            height="22"
            fill="#26D98E"
            rx="4"
          />

          <text
            x={x}
            y={y - 10 - 3}
            fill="#fff"
            fontSize={16}
            textAnchor="middle"
          >
            ZGA
          </text>
          <polygon
            points={`${x - 4},${y - 9} ${x + 4},${y - 9} ${x},${y - 5}`}
            fill="#26D98E"
          />
          <circle cx={x} cy={y} r={3} fill="#26D98E" />
        </g>
      )
    )
  }

  return (
    <div className="new-topBanner">
      <div className="new-topBanner-bg">
        <div className="new-topBanner-content">
          <div className="left">
            <div className="icon-wrapper only-desktop">
              <img src="/header/gia.svg" alt="" />
            </div>
            <div className="subtitle only-desktop">{data.subtitle2}</div>
            <div className="barChart-wrapper fadeInLeft">
              <div className="subtitle only-mobile">{data.subtitle2}</div>

              <div className="top">
                <Bar data={barData} />
                <div className="triangle only-desktop"></div>
              </div>
              <div className="bottom">
                <picture>
                  <source
                    srcSet="/products/gia/barTip-icon-m.svg"
                    media="(max-width: 640px)"
                  />
                  <img src="/products/gia/barTip-icon.svg" alt="zga" />
                </picture>
                <div className="description">{data.barChart.tip}</div>
                <div className="triangle only-mobile"></div>
              </div>
            </div>
            <div className="only-desktop">
              <ButtonRow link={consoleLink} />
            </div>
          </div>
          <div className="right fadeInUp">
            <div className="top">
              <div className="text">
                <div className="title only-desktop">{data.lineChart.title}</div>
                <img
                  src="/header/gia.svg"
                  alt="zga-icon"
                  className="only-mobile"
                />
                <div className="subtitle">{data.lineChart.subtitle}</div>
              </div>
              <div className="badge">
                <img src="/products/gia/realtime.gif" alt="realtime" />
                <div className="label">{data.lineChart.realtime}</div>
                <div className="triangle"></div>
              </div>
            </div>
            <div className="lineChart ">
              <div className="yTip">{data.lineChart.yTip}</div>
              <ResponsiveContainer width={"100%"} height={"100%"}>
                <LineChart
                  // width={690}
                  // height={260}
                  data={lineData}
                  margin={{
                    top: 30,
                    right: 30,
                    left: -30,
                    // bottom: 16,
                  }}
                >
                  <CartesianGrid
                    horizontal={false}
                    stroke="rgba(133, 150, 185, 0.2)"
                  />
                  <XAxis dataKey="date" stroke="#8596b9" tickCount={5} />
                  <YAxis stroke="#8596b9" />
                  <Tooltip content={<CustomTooltip />} />
                  <Line
                    type="monotone"
                    dataKey="unoptimized"
                    dot={false}
                    stroke="rgb(91, 135, 255)"
                    name={data.internet}
                    isAnimationActive={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="optimized"
                    stroke="rgb(38, 217, 142)"
                    dot={false}
                    name="ZGA"
                    isAnimationActive={false}
                    label={(<CustomizedLabel />) as any}
                  ></Line>
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div className="timeOption">
              {["day", "week"].map(key => (
                <div
                  className={key === time ? "active" : ""}
                  onClick={() => {
                    setTime(key)
                    if (key === "day") {
                      fetchTicker.current = setInterval(() => {
                        fetchData("day")
                      }, 120000)
                    } else {
                      clearInterval(fakeDataTicker.current)
                      clearInterval(fetchTicker.current)
                    }
                    fetchData(key)
                  }}
                >
                  {data.subject[key]}
                </div>
              ))}
            </div>
            <div className="tip">
              {data.lineChart.tip1}
              <a
                href={
                  info.target
                    ? info.target.startsWith("http")
                      ? info.target
                      : `//${info.target}`
                    : undefined
                }
                target="_blank"
                style={{ display: "inline-block", marginLeft: "4px" }}
              >
                {info.target}
              </a>
            </div>
            <div className="tip">
              {data.lineChart.tip2
                .replace("{}", range || "")
                .replace("{}", info.source) || ""}
            </div>
          </div>
          <div
            className="only-mobile"
            style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 100 }}
          >
            <ButtonRow width={642} />
          </div>
        </div>
      </div>
    </div>
  )
}
const KeyFeatures = () => {
  return (
    <div className="gia-keyFeatures">
      {static_data.keyFeatures.map((text, idx) => (
        <div className="gia-keyFeatures-item">
          <img src={`/products/gia/feature-icon-${idx + 1}.svg`} alt={text} />
          <div>{text}</div>
        </div>
      ))}
    </div>
  )
}
export default function Home({ data, consoleLink }) {
  const { strapiTestimonials, allStrapiPosts } = data
  return (
    <div className="gia">
      <SEO {...static_data.seo} featuredImage="/featuredImage/gia.jpg" />
      <NewTop />
      <TopBanner consoleLink={consoleLink} />
      <ProductNav
        name={static_data.gia}
        menus={static_data.navigation}
        minWidth={1100}
      />
      {/* <a id="overview" />
      <KeyFeatures />
      <Feature /> */}
      <a id="keyFeatures" />
      <Highlight dropColor="#f5f9ff" />
      <div className="gia-usecase-wrapper">
        <Usecase />
      </div>
      <Success
        dropColor="#fff"
        button={
          <Button
            size="l"
            width={476}
            // className="white"
            onClick={() => {
              window.open(
                consoleLink ||
                  "https://console.zenlayer.com/gia?source=website-gia"
              )
            }}
          >
            {static_data.topBanner.buttonText}
          </Button>
        }
      />
      <Architecture />
      <B5 />
      <a id="testimonials" />
      <Feedback {...strapiTestimonials} />
      <a id="learnMore" />
      <Solution
        title={static_data.solution.title}
        description={static_data.solution.description}
      />
      <ProductBlog
        title={static_data.blog.title}
        // bgColor="linear-gradient(180deg,rgba(255,253,242,1) 0%,rgba(255,249,225,1) 100%)"
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      />
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        categories: {
          elemMatch: { name: { eq: "上海层峰 Global Accelerator" } }
        }
        author: {}
      }
    ) {
      edges {
        node {
          slug
          thumbnail {
            localFile {
              publicURL
            }
          }
          wp_date
          categories {
            name
          }
          created_at
          content
          title
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
    strapiTestimonials(inPage: { eq: "gia" }) {
      customerTitle
      customerName
      content
      companyPreview {
        localFile {
          publicURL
        }
      }
      logo {
        localFile {
          publicURL
        }
      }
      companyName
    }
  }
`
