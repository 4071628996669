import React, { useState } from "react"
const loadText = require("src/utils").loadText
import Footer from "../../components/footer"
import Drop from "../../components/drop"
import SEO from "../../components/SEO"
import Form from "../../components/form"
import { sendData, LpPopButton } from "../../components/form-biz"
// import { AnimationWrap } from "../../components/block"
// import {
//   MapContainer,
//   Solution,
//   IconGroups,
const staticPath = "/lp-gia"
// } from "../../pages/products/bare-metal-cloud"
import { navigate, Link } from "gatsby"
// import { Benefits } from "../../pages/products/cloud-networking"
import "../../styles/lp-gia.less"
import Button from "../../components/ui/button"
// import { loadScriptToHead } from "../../utils"
// const url_data = loadText("url")
export const Highlight = ({ dropColor }: any) => {
  const [active, setActive] = useState(1)

  return (
    <div className="cloudWan-highlights">
      {/* <AnimationWrap> */}
      {dropColor && <Drop color={dropColor} top={0} />}
      <div
        className="page-middle only-desktop"
        style={{ position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            right: "64px",
            zIndex: 0,
            backgroundImage: `url("${staticPath}/highlight-pic${active}.png`,
            width: "784px",
            height: "614px",
          }}
        ></div>
      </div>
      <div
        className="cloudWan-highlights-content only-desktop"
        style={{
          backgroundImage: `url("${staticPath}/highlight-bg.svg")`,
          backgroundSize: "1920px 100%",
          backgroundRepeat: "no-repeat",
          margin: "0 auto",
          backgroundPosition: "top center",
          position: "relative",
          zIndex: 1,
        }}
      >
        <div className="font-36 semi-bold">{static_data.highlights.title}</div>
        {/* <div className="content-wrapper"> */}
        <div
          style={{ marginTop: "25px" }}
          className="cloudWan-highlights-content-body"
        >
          <div className="left mt-24">
            {static_data.highlights.content.map(({ name }, idx) => {
              const isActive = active === idx + 1
              return (
                <div
                  key={idx}
                  className={`item ${isActive ? "active" : ""}`}
                  onMouseEnter={() => {
                    setActive(idx + 1)
                  }}
                >
                  <img
                    src={`${staticPath}/highlight-icon-${idx + 1}${
                      isActive ? "" : "-w"
                    }.svg`}
                    alt=""
                  />
                  <div className="font-20 semi-bold mt-24">{name}</div>
                </div>
              )
            })}
          </div>
          <ul className="right font-20">
            <div className="title">
              {static_data.highlights.content[active - 1].name}
            </div>
            {static_data.highlights.content[active - 1].features.map(
              (c, idx) => (
                <li key={idx} dangerouslySetInnerHTML={{ __html: c }} />
              )
            )}
          </ul>
        </div>
      </div>
      {/* </div> */}
      <div className="only-mobile">
        <ul className="content">
          {static_data.highlights.content.map(({ name, features }, idx) => {
            const key = idx + 1
            const height = [814, 604, 656, 732]
            return (
              <div
                style={{
                  background:
                    "linear-gradient(144deg, #14BBFF 0%, #005CEC 100%)",
                }}
              >
                {idx === 0 && <Drop color={dropColor} />}
                <li
                  key={idx}
                  style={{
                    backgroundImage: `url("${staticPath}/highlight-${key}-m-bg.svg")`,
                    height: height[idx],
                  }}
                >
                  {idx === 0 && (
                    <div className="title font-36 semi-bold">
                      {static_data.highlights.title}
                    </div>
                  )}
                  <div className="inner">
                    <div className="name">
                      <div>
                        <img
                          src={`${staticPath}/highlight-icon-${key}.svg`}
                          style={{
                            width: 48,
                          }}
                        />
                        <p className="mt-24 font-24 semi-bold t-color">
                          {name}
                        </p>
                      </div>
                    </div>
                    <ul className="features">
                      {features.map((c, i) => (
                        // <img src="" alt="" />
                        <li
                          key={i}
                          dangerouslySetInnerHTML={{ __html: c }}
                        ></li>
                      ))}
                    </ul>
                  </div>
                </li>
              </div>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
const static_data = loadText("lp-gia")
const KV = () => {
  return (
    <div className="lp-gia-kv">
      <div className="lp-gia-kv-element">
        <div className="lp-gia-kv-bg">
          <div className="lp-gia-content">
            <div className="lp-gia-kv-top">
              <Link to="/">
                <img src="/lp-gia/logo.svg" alt="logo" />
              </Link>
              <div className="lp-gia-kv-description">
                {static_data.topBanner.description}
              </div>
            </div>
            <div className="lp-gia-kv-title">{static_data.topBanner.title}</div>
            <div className="lp-gia-kv-subtitle">
              {static_data.topBanner.subtitle}
            </div>
            <div className="lp-gia-kv-feature">
              {static_data.topBanner.features.map((content, i) => (
                <div className="lp-gia-kv-feature-item">
                  <img src={`/lp-gia/kv-f${i + 1}.svg`} />
                  <div>{content}</div>
                </div>
              ))}
            </div>
            <div className="lp-gia-kv-btnRow">
              <Button
                width={424}
                className="white"
                size="l"
                onClick={() => {
                  window.open(
                    "https://console.zenlayer.com/auth/signup?utm_source=google&utm_medium=cpc&utm_campaign=ZGA&utm_id=ZGA"
                  )
                }}
              >
                {static_data.topBanner.buttonText}
              </Button>
            </div>
            <div className="lp-gia-form-title">{static_data.form.title}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
export const Feature = () => {
  const bg = [
    "linear-gradient(142deg, #F2FBFF 0%, #E1F5FF 100%)",
    "linear-gradient(136deg, #F1FBFB 0%, #DEF6F5 100%)",
    "linear-gradient(148deg, #FBF6FF 0%, #F4E6FF 100%)",
  ]
  return (
    <div className="lp-gia-feature">
      {static_data.features.map(({ title, description }, idx) => (
        <div
          className="lp-gia-feature-item-wrapper"
          style={{ background: bg[idx] }}
        >
          <div
            style={{ backgroundImage: `url("/lp-gia/kf-${idx + 1}.svg")` }}
            className="lp-gia-feature-item"
          >
            <div className="lp-gia-feature-item-title">{title}</div>
            <div className="lp-gia-feature-item-description">{description}</div>
          </div>
        </div>
      ))}
    </div>
  )
}
export const Usecase = () => {
  return (
    <div className="lp-gia-usecase">
      <div className="lp-gia-usecase-title">{static_data.usecase.title}</div>
      <div className="lp-gia-usecase-subtitle">
        {static_data.usecase.subtitle}
      </div>

      <div className="lp-gia-content">
        <div className="lp-gia-usecase-content">
          {static_data.usecase.content.map(({ name, description }, idx) => (
            <div className="lp-gia-usecase-item">
              <img src={`/lp-gia/usecase-${idx + 1}.svg`} alt={name} />
              <div className="lp-gia-usecase-item-content">
                <div className="lp-gia-usecase-item-title">{name}</div>
                <div
                  className="lp-gia-usecase-item-description"
                  dangerouslySetInnerHTML={{ __html: description }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export const Success = ({ dropColor, button }: any) => {
  const data = static_data.success
  return (
    <div className="lp-gia-success">
      <Drop color={dropColor || "rgb(7,114,223)"} className="only-desktop" />
      <Drop color={dropColor || "rgb(42,113,232)"} className="only-mobile" />
      <div className="lp-gia-success-bg">
        <div className="title">{data.title}</div>
        {data.contents.map(({ name, usecase, result }, idx) => (
          <div className="lp-gia-success-item">
            <div className="left">
              <div className="bg">
                <img src={`/lp-gia/success-icon-${idx + 1}.png`} alt={name} />
                <div>
                  <div className="title">{name}</div>
                  <div className="label">{`${data.subject.usecase}: `}</div>
                  <div className="usecase">{usecase}</div>
                </div>
              </div>
            </div>
            <div className="right">
              <Drop color="#F5F9FF" className="only-mobile" />
              <div className="bg">
                <div className="label">{`${data.subject.result}: `}</div>
                <div className="result">{result}</div>
                <picture>
                  <source
                    srcSet={`/lp-gia/success-data${idx + 1}-m.svg`}
                    media="(max-width: 640px)"
                  />
                  <img
                    src={`/lp-gia/success-data${idx + 1}.svg`}
                    alt={result}
                  />
                </picture>
              </div>
            </div>
          </div>
        ))}
        {button ? (
          button
        ) : (
          <LpPopButton
            source="上海层峰 Global Accelerator Landing Page"
            buttonWidth={476}
          >
            {data.buttonText}
          </LpPopButton>
        )}
      </div>
    </div>
  )
}
export default function Index() {
  return (
    <div className="lp-gia">
      <SEO {...static_data.seo} featuredImage="/featuredImage/gia.jpg" />
      <KV />
      <div className="lp-gia-form-wrapper">
        <div className="lp-gia-form lp-gia-content">
          <Drop color="rgb(9,125,229)" bgColor="#fff" />
          <Form
            type="lp"
            onSuccessWithoutModal={() => {
              navigate("/lp/thanks")
            }}
            onSubmit={async data => {
              await sendData({
                data,
                url: "https://go.zenlayer.com/l/879902/2021-04-19/3v5dj",
                source: "上海层峰 Global Accelerator Landing Page",
              })
            }}
          />
        </div>
      </div>
      <Feature />
      <Usecase />
      <Highlight />
      <Success />
      <Footer />
    </div>
  )
}
